import { useEffect, useMemo, useRef } from 'react';
import { useField } from 'react-final-form';
import { getExperimentData, getSourceIdByLocale } from '@wkda/funnel-utils';
import { useAppConfiguration } from '../useAppConfiguration';
import { useConsumerSourceId, useGetSelfEvaluation } from '../useDataHooks';
import useConstant from '../useConstant';
import { useModulesStore } from '../useModules';
import useCarleadHashParam from '../useCarleadHashParam';
import { getBookingPageUxFeatures } from './helpers';
import { useMileagePriceTag } from '../../booking/hooks';
import { mileagePriceTagTrackingParams } from './mileagePriceTagTracking';
import { useConsumerCarleadMarketingProperty } from '../useDataHooks/consumerCarlead';
import { useGetWKDALeadCookie } from '../useGetWKDALeadCookie';
/**
 * Util function returns params depend on application config.
 */
function getTrackingParamsByConfig(config, consumerSourceId) {
  const {
    appName,
    appVersion,
    sourceId: configSourceId,
    locale
  } = config;
  const sourceId = consumerSourceId || configSourceId || getSourceIdByLocale(locale);
  return {
    appver: appName.replace('@wkda/funnels-', '') + "@" + appVersion,
    appname: appName,
    source: sourceId
  };
}
function useGetMarketingTrackingService() {
  const {
    tracking
  } = useModulesStore();
  return tracking.marketingTrackingService;
}
const trackLandingWrapper = (config, consumerSourceId, carleadHash, params, service, additionalParams) => {
  const {
    trackLandingPageType,
    appName,
    env
  } = config;

  // booking landing is tracked differently (with external tracking)
  const isBookingLanding = appName.startsWith('@wkda/') && trackLandingPageType === 'booking-handover' && !!carleadHash;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  if (trackLandingPageType) {
    const trackingPayload = {
      pageType: trackLandingPageType,
      ...configParams,
      ...params
    };
    if (isBookingLanding) {
      var _window;
      return service.trackExternal({
        externalType: 'carlead',
        externalId: carleadHash
      }, {
        ...trackingPayload,
        category: 'booking',
        action: 'booking-landing',
        uxFeatures: getBookingPageUxFeatures((_window = window) === null || _window === void 0 || (_window = _window.location) === null || _window === void 0 ? void 0 : _window.href),
        ...mileagePriceTagTrackingParams(additionalParams === null || additionalParams === void 0 ? void 0 : additionalParams.mileagePriceTag)
      });
    } else {
      return service.trackLanding(trackingPayload);
    }
  } else {
    if (env !== 'prod') console.warn('[Warning] - Track landing works only if trackLandingPageType is added to the funnel config');
  }
};
const trackLeadFieldChangedWrapper = _ref => {
  let {
    config,
    consumerSourceId,
    fieldName,
    method,
    service,
    eventData
  } = _ref;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  return service.trackLeadFieldChanged({
    ...configParams,
    leadFieldChanged: fieldName,
    method,
    eventData
  });
};
const trackExternalWrapper = function (config, consumerSourceId, externalType, externalId, category, action) {
  let additionalData = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
  let service = arguments.length > 7 ? arguments[7] : undefined;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  return service.trackExternal({
    externalType,
    externalId
  }, {
    ...configParams,
    action,
    category,
    ...additionalData
  });
};
export function useTrackLandingCallback(params) {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  const carleadHash = useCarleadHashParam();
  const mileagePriceTag = useMileagePriceTag();
  return () => trackLandingWrapper(config, sourceId, carleadHash, params, service, {
    mileagePriceTag
  });
}
export function useTrackLanding(params) {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const carleadHash = useCarleadHashParam();
  const configConstant = useConstant(() => config);
  const sourceIdConstant = useConstant(() => sourceId);
  const paramsConstant = useConstant(() => params);
  const carleadHashConstant = useConstant(() => carleadHash);
  const service = useGetMarketingTrackingService();
  const mileagePriceTag = useMileagePriceTag();
  useEffect(() => {
    trackLandingWrapper(configConstant, sourceIdConstant, carleadHashConstant, paramsConstant, service, {
      mileagePriceTag
    });
  }, [configConstant, sourceIdConstant, carleadHashConstant, paramsConstant, service]);
}
export function useTrackLeadFieldChanged() {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  return (fieldName, method, eventData) => trackLeadFieldChangedWrapper({
    config,
    consumerSourceId: sourceId,
    fieldName,
    method,
    service,
    eventData
  });
}
export function useTrackExternal() {
  const config = useAppConfiguration();
  const sourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  return useMemo(() => (externalType, externalId, category, action, additionalData) => trackExternalWrapper(config, sourceId, externalType, externalId, category, action, additionalData, service), [config, service, sourceId]);
}
export function useTrackSelfEvaFormSubmit() {
  const {
    data: selfEvaluation
  } = useGetSelfEvaluation();
  const trackExternal = useTrackExternal();
  function track(step, status) {
    const experiment = getExperimentData();
    return trackExternal('self-evaluation', selfEvaluation.id, 'self-evaluation', 'form-submit', {
      step,
      stepSubmitStatus: status,
      currentUrl: window.location.href,
      landingPageName: window.location.pathname,
      ...(experiment ? {
        currentExperiment: [experiment]
      } : {})
    });
  }
  return {
    trackSubmitSuccess: step => track(step, 'success'),
    trackSubmitError: step => track(step, 'failure')
  };
}
export function useTrackSelfEvaFieldChanged() {
  const trackExternal = useTrackExternal();
  const {
    data: selfEvaluation
  } = useGetSelfEvaluation();
  return (field, method) => {
    if (!(selfEvaluation !== null && selfEvaluation !== void 0 && selfEvaluation.id)) return;
    const experiment = getExperimentData();
    return trackExternal('self-evaluation', selfEvaluation.id, 'self-evaluation', 'field-changed', {
      fieldChanged: field,
      method,
      currentUrl: window.location.href,
      landingPageName: window.location.pathname,
      ...(experiment ? {
        currentExperiment: [experiment]
      } : {})
    });
  };
}
export function useTrackSelfEvaLandingFunction(params) {
  const config = useAppConfiguration();
  const consumerSourceId = useConsumerSourceId();
  const service = useGetMarketingTrackingService();
  const {
    trackLandingPageType,
    funnelType: configFunnelType
  } = config;
  const configParams = getTrackingParamsByConfig(config, consumerSourceId);
  const experiment = getExperimentData();
  return externalId => {
    const {
      funnelType,
      ...rest
    } = params || {};
    const payload = {
      ...configParams,
      currentUrl: window.location.href,
      landingPageName: window.location.pathname,
      pageType: trackLandingPageType || 'self-evaluation',
      funnelType: funnelType || configFunnelType,
      ...(experiment ? {
        currentExperiment: [experiment]
      } : {}),
      ...rest
    };
    return service.trackExternal({
      externalType: 'self-evaluation',
      externalId
    }, {
      category: 'self-evaluation',
      action: 'self-evaluation-landing',
      ...payload
    });
  };
}
export function useTrackSelfEvaLanding(params) {
  const paramsConstant = useConstant(() => params);
  const {
    data: selfEvaluation
  } = useGetSelfEvaluation();
  const track = useTrackSelfEvaLandingFunction(paramsConstant);
  useEffect(() => {
    if (!(selfEvaluation !== null && selfEvaluation !== void 0 && selfEvaluation.id)) return;
    track(selfEvaluation.id);
  }, [paramsConstant, selfEvaluation === null || selfEvaluation === void 0 ? void 0 : selfEvaluation.id]);
}
export function useTrackSelfEvaFieldAutoFill(_ref2) {
  let {
    name,
    step
  } = _ref2;
  const stepMktProp = useConsumerCarleadMarketingProperty('selfInspectionStep');
  const field = useField(name, {
    subscription: {
      value: true,
      pristine: true
    }
  });
  const track = useTrackSelfEvaFieldChanged();
  const isAllowedToTrack = useRef(true);
  useEffect(() => {
    const isCorrectStep = stepMktProp !== null && stepMktProp !== void 0 && stepMktProp.value ? +(stepMktProp === null || stepMktProp === void 0 ? void 0 : stepMktProp.value) === step : step === 0 ?
    // `stepMktProp` value is not available on the first page load
    true : false;
    if (isAllowedToTrack.current && isCorrectStep && field.meta.pristine && field.input.value) {
      track(name, 'autofill');
      isAllowedToTrack.current = false;
    }
    if (!field.meta.pristine) {
      isAllowedToTrack.current = false;
    }
  }, [field.input.value, field.meta.pristine, name, step, stepMktProp === null || stepMktProp === void 0 ? void 0 : stepMktProp.value, track]);
}
export function useTrackCustomerBanner() {
  const service = useGetMarketingTrackingService();
  const config = useAppConfiguration();
  const configParams = getTrackingParamsByConfig(config);
  const wkdaLead = useGetWKDALeadCookie();
  const CJ_TRACKING_CTA_ATTR = 'data-cj-tracking-button-name';
  function getCJTrackingButtonNameByAttr(element) {
    const ctaName = element.getAttribute(CJ_TRACKING_CTA_ATTR);
    if (!ctaName) {
      console.warn("[CJ Tracking] No button name found on element by " + CJ_TRACKING_CTA_ATTR + " attribute.");
    }
    return ctaName;
  }
  function trackShownEvent(bannerName) {
    return service.trackCustomerBanner({
      externalType: 'carlead',
      externalId: wkdaLead
    }, {
      ...configParams,
      action: 'customer-banner-shown',
      category: 'customer-banner',
      bannerName
    });
  }
  function trackCTAClickEvent(bannerName, ctaName) {
    service.trackCustomerBanner({
      externalType: 'carlead',
      externalId: wkdaLead
    }, {
      ...configParams,
      action: 'customer-banner-cta-click',
      category: 'customer-banner',
      bannerName,
      ctaName
    });
  }
  return {
    trackShownEvent,
    trackCTAClickEvent,
    CJ_TRACKING_CTA_ATTR,
    getCJTrackingButtonNameByAttr
  };
}