export const carleadQuestionnairePartialFlow = {
  SELLPOINT: {
    name: 'SELLPOINT',
    cookieName: 'surveySellpoint',
    next: ['SELL_TYPES', 'email'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
      email: ['SELLPOINT_NOT_PLANNED']
    }
  },
  SELL_TYPES: {
    name: 'SELL_TYPES',
    cookieName: 'surveySelltypes',
    next: ['email'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      email: ['PRIVATE', 'DEALER']
    }
  }
};
export const carleadQuestionnaireWithTradeInPartialFlow = {
  SELLPOINT: {
    name: 'SELLPOINT',
    cookieName: 'surveySellpoint',
    next: ['SELL_TYPES', 'email'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
      email: ['SELLPOINT_NOT_PLANNED']
    }
  },
  SELL_TYPES: {
    name: 'SELL_TYPES',
    cookieName: 'surveySelltypes',
    next: ['TRADE_IN', 'email'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      TRADE_IN: ['DEALER'],
      email: ['PRIVATE']
    }
  },
  TRADE_IN: {
    name: 'TRADE_IN',
    cookieName: 'surveyTradeIn',
    next: ['email'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      email: ['YES', 'NO']
    }
  }
};
export const carleadQuestionnaireNoEmailPartialFlow = {
  SELLPOINT: {
    name: 'SELLPOINT',
    cookieName: 'surveySellpoint',
    next: ['SELL_TYPES', 'finish'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      SELL_TYPES: ['SELLPOINT_NOW', 'SELLPOINT_4_WEEKS', 'SELLPOINT_3_MONTHS'],
      finish: ['SELLPOINT_NOT_PLANNED']
    }
  },
  SELL_TYPES: {
    name: 'SELL_TYPES',
    cookieName: 'surveySelltypes',
    next: ['finish'],
    nodeType: 'questionnaireField',
    inputFieldType: 'select',
    guards: {
      finish: ['PRIVATE', 'DEALER']
    }
  }
};