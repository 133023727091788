import { createPersistDecorator } from '@wkda/funnel-components';
import { DataLocalStorage, isLocalStorageSupported } from '@wkda/funnel-utils';
import { useCallback, useMemo } from 'react';
import { useCarleadConfiguration } from '../hooks/useCarleadCreationConfiguration';
import { DSBDataLocalStorage } from './DSBDataLocalStorage';
import { DSBDataQueryStringStorage } from './DSBDataQueryStringStorage';
import { QueryStringCache } from './QueryStringCache';
export function useCacheStorage() {
  const {
    locale,
    fullLocale,
    featuresFlags: {
      exactMileage
    }
  } = useCarleadConfiguration();
  const storage = useMemo(() => {
    return isLocalStorageSupported() ? new DSBDataLocalStorage(new DataLocalStorage(), {
      locale,
      fullLocale,
      exactMileage
    }) : new DSBDataQueryStringStorage(new QueryStringCache(), {
      locale,
      fullLocale,
      exactMileage
    });
  }, [locale, fullLocale, exactMileage]);
  const getData = useCallback(function getData() {
    const item = storage.getItem(storage.formStorageKey);
    try {
      return JSON.parse(item);
    } catch (e) {
      console.error("[Form Cache] Error getting data: " + item, e);
      return null;
    }
  }, [storage]);
  const setData = useCallback(function setData(values) {
    storage.setItem(storage.formStorageKey, JSON.stringify(values));
  }, [storage]);
  const API = useMemo(() => {
    return {
      storage,
      getData,
      setData
    };
  }, [storage, getData, setData]);
  return API;
}
export function usePersistDecorator() {
  const {
    storage
  } = useCacheStorage();
  const persistDecorator = useMemo(() => {
    return createPersistDecorator({
      name: storage.formStorageKey,
      debounceTime: 100,
      // TODO: DSBCoreDataLocalStorage is missing some of the Storage methods
      // Probable it's better to fork `final-form-persist` to make `storage` property more generic
      // Current implementaion is using only `setItem`, `getItem` and `removeItem` methods
      // @ts-expect-error
      storage
    });
  }, [storage]);
  return persistDecorator;
}