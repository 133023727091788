import { useTrackLeadFieldChanged } from '@wkda/funnel-components';
import { useCarleadDataLayer } from './useCarleadDataLayer';
export function useCarleadFieldChangedTracking() {
  // same tracking is needed for DataLayer and MarketingTrackingService
  const dataLayer = useCarleadDataLayer();
  const trackLeadFieldChanged = useTrackLeadFieldChanged();
  return (fieldName, source, eventData) => {
    dataLayer.leadFieldChanged(fieldName, source);
    trackLeadFieldChanged(fieldName, source, eventData);
  };
}